import React, { useState, useEffect } from 'react';
import upGreenRight from '../../srcImages/UpGreenRight.png'
import downRedRight from '../../srcImages/DownRedRight.png'
import BasicTickerEvaluaton from '../../Components/BasicTickerEvaluaton/BasicTickerEvaluaton'

const CURRENT_HOLDINGS = 
  [
    {
      ticker: "DIA",
      costBasis: '0.0',
      unitsOnHand: 0, 
      calculateAccumulatedProfitLoss: true,            
    },
    {
      ticker: "VOO",
      costBasis: '0.0',
      unitsOnHand: 0,
      calculateAccumulatedProfitLoss: true,             
    },
    {
      ticker: "QQQ",
      costBasis: '0.0',
      unitsOnHand: 0,
      calculateAccumulatedProfitLoss: true,             
    },
    {
      ticker: "AAPL",
      costBasis: '20.05',
      unitsOnHand: 855,
      calculateAccumulatedProfitLoss: true,      
    },
    {
      ticker: "AMZN",
      costBasis: '94.69',
      unitsOnHand: 675,
      calculateAccumulatedProfitLoss: true,           
    },
    {
      ticker: "AVGO",
      costBasis: '861.48',
      unitsOnHand: 75,
      calculateAccumulatedProfitLoss: true,           
    },
    {
      ticker: "BHC",
      costBasis: '23.64',
      unitsOnHand: 3930, 
      calculateAccumulatedProfitLoss: true,            
    },
    {
      ticker: "COST",
      costBasis: '289.90', 
      unitsOnHand: 75,
      calculateAccumulatedProfitLoss: true,            
    },
    {
      ticker: "CRM",
      costBasis: '166.26',
      unitsOnHand: 220, 
      calculateAccumulatedProfitLoss: true,            
    },
    {
      ticker: "DD",
      costBasis: '75.03',
      unitsOnHand: 800,
      calculateAccumulatedProfitLoss: true,            
    },
    {
      ticker: "EL",
      costBasis: '206.67',
      unitsOnHand: 425, 
      calculateAccumulatedProfitLoss: true,            
    },
    {
      ticker: "F",
      costBasis: '9.58',
      unitsOnHand: 5500, 
      calculateAccumulatedProfitLoss: true,            
    },
    {
      ticker: "FL",
      costBasis: '40.22',
      unitsOnHand: 750,
      calculateAccumulatedProfitLoss: true,             
    },
    {
      ticker: "GOOGL",
      costBasis: '58.37',
      unitsOnHand: 645,
      calculateAccumulatedProfitLoss: true,             
    },
    {
      ticker: "LIN",
      costBasis: '311.16',
      unitsOnHand: 190, 
      calculateAccumulatedProfitLoss: true,            
    },
    {
      ticker: "LLY",
      costBasis: '257.64',
      unitsOnHand: 110,
      calculateAccumulatedProfitLoss: true,
    },
    {
      ticker: "META",
      costBasis: '194.64',
      unitsOnHand: 323,
      calculateAccumulatedProfitLoss: true,             
    },
    {
      ticker: "MSFT",
      costBasis: '287.22',
      unitsOnHand: 210, 
      calculateAccumulatedProfitLoss: true,            
    },
    {
      ticker: "NVDA",
      costBasis: '155.89',
      unitsOnHand: 130,
      calculateAccumulatedProfitLoss: true,             
    },
    {
      ticker: "PANW",
      costBasis: '177.93',
      unitsOnHand: 250, 
      calculateAccumulatedProfitLoss: true,            
    },
    {
      ticker: "PG",
      costBasis: '146.48',
      unitsOnHand: 550,
      calculateAccumulatedProfitLoss: true,             
    },
    {
      ticker: "TJX",
      costBasis: '64.12',
      unitsOnHand: 750,
      calculateAccumulatedProfitLoss: true,             
    }
    

  ];


function ClubHolds() {

  const [stockSymbolToFetch,setStockSymbolToFetch] = useState('AAPL')
  const [headerValue,setHeaderValue] = useState('Club Holds')
  const [todaysPercentageChange, setTodaysPercentageChange] = useState(0.0);
  const [isTodaysChangePositive, setIsTodaysChangePositive] = useState(true);
  const [slope, setSlope] = useState(0.0)
  const [currentHoldings,setCurrentHoldings]=useState(CURRENT_HOLDINGS);

  const onSelectTickerButtonHandler=(tickerToEvaluate)=>
  {
    setStockSymbolToFetch(tickerToEvaluate)
    //console.log("Setting stockSymbolToFetch: " +stockSymbolToFetch)
  }

  const onSetHeader=(headerValueIn)=>
  {
    setHeaderValue(headerValueIn)
  }

  const onSetTodaysPercentageChange = (percentageChange, isChnagePositive) => {
    setTodaysPercentageChange(percentageChange);
    setIsTodaysChangePositive(isChnagePositive);
  }

  const onSetSlope = (slopeIn) => {
    setSlope(slopeIn)
  }

  useEffect(() => {
    document.title = "Club Holds"
 }, []);

  useEffect(() => {  
    //console.log("Running useEffect for: stockSymbolToFetch: " +stockSymbolToFetch)
}, [stockSymbolToFetch,headerValue,slope]);

  return (
    <div className="text-center">
    <header className="bg-sky-100 text-sky-600 text-3xl font-bold h-18 justify-items-center">
      <div>
        {headerValue}
      </div>   
      <div>
                {isTodaysChangePositive === true ?
                    <div className='text-green-600 text-3xl font-bold'>
                        Today's Change: {todaysPercentageChange} %
                    </div> :
                    <div className='text-red-600 text-3xl font-bold'>
                        Today's Change: {todaysPercentageChange} %
                    </div>
                    }
      </div>     
      <div>
        {slope >= 0.0 ?
          <div className='text-green-600 text-3xl font-bold'>            
                {/*Exponential change: {slope}  */}              
                <img className="inline-block w-10 h-8 ml-7 " src={upGreenRight} alt=""></img>                           
          </div> :
          <div className='text-red-600 text-3xl font-bold'>
                {/*} Exponential change: {slope} */} 
                  <img className="inline-block w-12 h-10 ml-7" src={downRedRight} alt=""></img> 
          </div>
          }
      </div>          
    </header>

    <BasicTickerEvaluaton onSelectTickerButtonHandler = {onSelectTickerButtonHandler} onSetHeader = {onSetHeader} baseHeader='Club Holds'
     onSetTodaysPercentageChange={onSetTodaysPercentageChange}
                          onSetSlope = {onSetSlope} tickerEntries={currentHoldings} backgroundLeft='bg-sky-100'
                          buttonBackgroundColor='bg-sky-400'/>
    {/*<StockQuote stockSymbol={stockSymbolToFetch}/>*/}
    </div>
  );
}

export default ClubHolds;
