import React, { useState, useEffect } from 'react';
import upGreenRight from '../../srcImages/UpGreenRight.png'
import downRedRight from '../../srcImages/DownRedRight.png'
import BasicTickerEvaluaton from '../../Components/BasicTickerEvaluaton/BasicTickerEvaluaton'

const CURRENT_HOLDINGS = 
  [
    {
      ticker: "DIA",
      costBasis: '0.0',
      unitsOnHand: 0, 
      calculateAccumulatedProfitLoss: true,            
    },
    {
      ticker: "VOO",
      costBasis: '0.0',
      unitsOnHand: 0,
      calculateAccumulatedProfitLoss: true,             
    },
    {
      ticker: "QQQ",
      costBasis: '0.0',
      unitsOnHand: 0,
      calculateAccumulatedProfitLoss: true,             
    },
    {
      ticker: "CTRA",
      costBasis: '27.14', 
      unitsOnHand: 2600,
      calculateAccumulatedProfitLoss: true,            
    },
    {
      ticker: "DHR",
      costBasis: '247.62',
      unitsOnHand: 550,
      calculateAccumulatedProfitLoss: true,            
    },
    {
      ticker: "DIS",
      costBasis: '137.31',
      unitsOnHand: 1320,
      calculateAccumulatedProfitLoss: true,             
    },
    {
      ticker: "ETN",
      costBasis: '228.96',
      unitsOnHand: 250, 
      calculateAccumulatedProfitLoss: true,            
    },
    {
      ticker: "GEHC",
      costBasis: '75.66',
      unitsOnHand: 1245,
      calculateAccumulatedProfitLoss: true,             
    },
    {
      ticker: "HON",
      costBasis: '182.30',
      unitsOnHand: 530,
      calculateAccumulatedProfitLoss: true,             
    },
    {
      ticker: "MS",
      costBasis: '88.12',
      unitsOnHand: 1475,  
      calculateAccumulatedProfitLoss: true,           
    },
    {
      ticker: "SBUX",
      costBasis: '87.01',
      unitsOnHand: 800,   
      calculateAccumulatedProfitLoss: true,          
    },
    {
      ticker: "STZ",
      costBasis: '236.49',
      unitsOnHand: 350,
      calculateAccumulatedProfitLoss: true,             
    },
    {
      ticker: "SWK",
      costBasis: '90.53',
      unitsOnHand: 740, 
      calculateAccumulatedProfitLoss: true,            
    },
    {
      ticker: "WFC",
      costBasis: '33.43',
      unitsOnHand: 3090, 
      calculateAccumulatedProfitLoss: true,            
    },
    {
      ticker: "WYNN",
      costBasis: '97.69',
      unitsOnHand: 375, 
      calculateAccumulatedProfitLoss: true,            
    }
    

  ];


function ClubPreferences() {

  const [stockSymbolToFetch,setStockSymbolToFetch] = useState('AAPL')
  const [headerValue,setHeaderValue] = useState('Club Buys')
  const [todaysPercentageChange, setTodaysPercentageChange] = useState(0.0);
  const [isTodaysChangePositive, setIsTodaysChangePositive] = useState(true);
  const [slope, setSlope] = useState(0.0)
  const [currentHoldings,setCurrentHoldings]=useState(CURRENT_HOLDINGS);

  const onSelectTickerButtonHandler=(tickerToEvaluate)=>
  {
    setStockSymbolToFetch(tickerToEvaluate)
    //console.log("Setting stockSymbolToFetch: " +stockSymbolToFetch)
  }

  const onSetHeader=(headerValueIn)=>
  {
    setHeaderValue(headerValueIn)
  }

  const onSetTodaysPercentageChange = (percentageChange, isChnagePositive) => {
    setTodaysPercentageChange(percentageChange);
    setIsTodaysChangePositive(isChnagePositive);
  }

  const onSetSlope = (slopeIn) => {
    setSlope(slopeIn)
  }

  useEffect(() => {
    document.title = "Club Buys"
 }, []);

  useEffect(() => {  
    //console.log("Running useEffect for: stockSymbolToFetch: " +stockSymbolToFetch)
}, [stockSymbolToFetch,headerValue,slope]);

  return (
    <div className="text-center">
    <header className="bg-blue-100 text-blue-600 text-3xl font-bold h-18 justify-items-center">
      <div>
        {headerValue}
      </div>   
      <div>
                {isTodaysChangePositive === true ?
                    <div className='text-green-600 text-3xl font-bold'>
                        Today's Change: {todaysPercentageChange} %
                    </div> :
                    <div className='text-red-600 text-3xl font-bold'>
                        Today's Change: {todaysPercentageChange} %
                    </div>
                    }
      </div>     
      <div>
        {slope >= 0.0 ?
          <div className='text-green-600 text-3xl font-bold'>            
                {/*Exponential change: {slope}  */}              
                <img className="inline-block w-10 h-8 ml-7 " src={upGreenRight} alt=""></img>                           
          </div> :
          <div className='text-red-600 text-3xl font-bold'>
                {/*} Exponential change: {slope} */} 
                  <img className="inline-block w-12 h-10 ml-7" src={downRedRight} alt=""></img> 
          </div>
          }
      </div>          
    </header>

    <BasicTickerEvaluaton onSelectTickerButtonHandler = {onSelectTickerButtonHandler} onSetHeader = {onSetHeader} baseHeader='Club Favorites'
     onSetTodaysPercentageChange={onSetTodaysPercentageChange}
                          onSetSlope = {onSetSlope} tickerEntries={currentHoldings} backgroundLeft='bg-blue-100'
                          buttonBackgroundColor='bg-blue-400'/>
    {/*<StockQuote stockSymbol={stockSymbolToFetch}/>*/}
    </div>
  );
}

export default ClubPreferences;
