import React, { useState, useEffect } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom'; 
import './App.css';
import home from './srcImages/home.png'
import CurrentHoldings from './Pages/CurrentHoldings/CurrentHoldings';
import DividendEntries from './Pages/DividendEntries/DividendEntries';
import ClubPreferences from './Pages/ClubPreferences/ClubPreferences';
import ClubHolds from './Pages/ClubHolds/ClubHolds';
import upGreenRight from './srcImages/UpGreenRight.png'
import downRedRight from './srcImages/DownRedRight.png'
import RootLayout from './Pages/RootLayout';
import HomePage from './Pages/HomePage/HomePage';
import MagicFormula from './Pages/MagicFormula/MagicFormula';
import Banks from './Pages/Banks/Banks';
import Simulator from './Pages/Simulator/Simulator';
import WatchList from './Pages/WatchList/WatchList';
import HistoricalDividends from './Pages/HistoricalDividends/HistoricalDividends';
import StatementSpreadSheet from './Pages/StatementSpreadSheet/StatementSpreadSheet';

import {Amplify} from 'aws-amplify'
import awsconfig from './aws-exports'
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';


Amplify.configure(awsconfig)

const router = createBrowserRouter([
  {
    path: '/',
    element: <RootLayout />,
    children: [
      {path: '/', element: <HomePage/>},
      {path: '/current', element: <CurrentHoldings/>},
      {path: '/dividendentries', element: <DividendEntries/>},
      {path: '/clubpreferences', element: <ClubPreferences/>},
      {path: '/clubholds', element: <ClubHolds/>},
      {path: '/magicformula', element: <MagicFormula/>},
      {path: '/banks', element: <Banks/>}, 
      {path: '/watchlist', element: <WatchList/>},
      {path: '/historicaldividendentries', element: <HistoricalDividends/>},
      {path: '/simulator', element: <Simulator/>},
      {path: '/statmententries', element: <StatementSpreadSheet/>} 
    ]
  },
  
]);
function App() {

  

  return (
      <Authenticator>
        {({ signOut, user }) => (
          <div className="App">
            <RouterProvider router={router}/>
            <button onClick={signOut}>Sign out</button>
          </div>
        )}
      </Authenticator>
    );
}

export default App;


{/*
      <div className="flex-1">
        <div className="bg-white px-2 py-0.5 h-10 flex">
          <div className="left md:w-3/4 sm:w-1/3 flex">  
            <div className='bg-gray-100 rounded mx-2 p-1'>       
              <img src={home} className="inline-block w-8 h-8  p-1 mx-2 my-1" alt=""></img>
              <span className='inline-block'>Home</span>
            </div>
            <div className='bg-blue-100 rounded mx-2 p-1'>  
              <img src={upGreenRight} className="inline-block w-8 h-8 p-1 mx-2 my-1" alt=""></img>
              <span className='inline-block'>Current</span>
            </div> 
            <div className='bg-orange-100 rounded mx-2 p-1'>  
              <img src={downRedRight} className="inline-block w-8 h-8 p-1 mx-2 my-1" alt=""></img>
              <span className='inline-block'>Possible</span>
            </div> 
          </div>
          
          <div className="right md:w-1/4 sm:w-2/3 flex ">
              <img src={home} className="w-7 h-7 bg-gray-100 rounded p-1 mx-2 my-1" alt=""></img>   
              <img src={home} className="w-7 h-7 bg-gray-100 rounded p-1 mx-2 my-1" alt=""></img>   
              <img src={home} className="w-7 h-7 bg-gray-100 rounded p-1 mx-2 my-1" alt=""></img>   
              <a href="" className="mt-4 mx-2 text-indigo-500">Logout</a>
          </div>
        </div>
    </div>
  */}